import { Injectable, Inject } from '@angular/core';
import { BusinessService } from './business.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CustomerThemeService {

  constructor(
    private businessService: BusinessService,
    @Inject(DOCUMENT) private document
  ) {
    this.subToBusiness();
  }

  primaryColour = ''

  secondaryColour = ''

  stylePreviousIndex = []

  /**
   * Date:25/01/2021
   * Created:Sree chand
   * Function to subscribe to business details
   */
  subToBusiness() {
    //console.log("SUB TO BUSINESS");
    this.businessService.businessDetail.subscribe(res => { //Observable subscription 
      // this.changeColorTheme('#6379FD !important', '#FF5264 !important'); //c1 blue c2 red
      // this.changeColorTheme('#FF5264 !important', '#6379FD !important'); //c1 red c2 blue
      // this.changeColorTheme('#EBAE7D !important', '#C64466 !important'); //c1 yellow-orange c2 dark pink
      // this.changeColorTheme('#FBD412 !important', '#333751 !important'); //c1 yellow c2 violet
      //this.changeColorTheme('#FF9671 !important', '#F9F871 !important'); //c1 yellow c2 violet
      if (res) {
        const adminThemeStyleSheet = this.document.styleSheets[4];
        if (this.stylePreviousIndex.length > 0) {
          this.stylePreviousIndex.forEach(index => {
            adminThemeStyleSheet.deleteRule(index)
          })
          this.stylePreviousIndex = []
        }
        this.primaryColour = res.primaryColorCustomer ? res.primaryColorCustomer : '#FF4539'
        this.secondaryColour = res.secondaryColorCustomer ? res.secondaryColorCustomer : '#1C33A6'
        // this.getLighterColor('#1C33A6', 10);
        this.changeColorTheme(this.primaryColour + ' ' + '!important', this.secondaryColour + ' ' + '!important');
      }

    });
  }

  /**
 * Date:25/01/2021
 * Created:Sree chand
 * Function to change color theme of app
 * @param color1 primary color 1
 * @param color2 primary color 2
 */
  changeColorTheme(color1, color2) {
    //console.log(this.document.styleSheets);
    const customerThemeStyleSheet = this.document.styleSheets[3];
    //console.log(customerThemeStyleSheet);



    //color: #6379FD;

    /*Blue color */
    const blueColorSelectors = [
      `.logo span, 
      .notif_icons a:hover,
      .log_section .dropdown-item:hover,
      .notif_btnb .dropdown-item:hover,
      .notif_btnb .dropdown-item:active,
      .views_btns,
      .der_pgs h6 a,
      .succes_cofbx h3,
      .pokrt .nav-link:hover,
      .pokrt .nav-link.active,
      .user_psg .pr_imgd h5,
      .appo_boxs span,
      .give_review:hover,
      .review_panel h2,
      .succes_panel h2,
      .eye_imgsec a,
      .arrow_openwrp li a i,
      .arrow_openwrp i,
      .changeph_barmg:hover,
      .personal_view_bcpg table tr td:last-child,
      .editpersonal_vbcpg:hover,
      .i_btntooltip,
      .savepersonal_vbcpg:hover,
      .shedule_hrap:hover,
      .profile_secs.tramb h4 i,
      .profile_secs.tramb h5 i,
      .book_app_txt,
      .complete_hdareas span,
      .pos_logoyrap h4 i,
      .pos_logoyrap h5 i`,
      '.btn-link , .der_pgs:hover , .der_pgs:hover h1 , .res_canc_btn , .no-consultant-container ',
      '.appointment-type .app-token , .type-is-booking , .d-b-c , .bre_updbtncan , .da_pgs:hover',
      ' .log_sec_box span a   , .or_box , .no-consultant-image , .or_wrd , .password-eye , .da_pgs.disabled',
      ' .mornin_update span:first-child:after , .mornin_update span:last-child:after , .sec_pgw:hover , .sec_pgw a , .logsec_logo h3 , .avail_head h2 , .tab-heading h3  ',
      ' .confirmimgbox_inntab , .gate_waycover h2 span , .services_count a:hover , .remind-me-text',
      ' .view-vitals:hover', '.page-link',
      '.plus_editpanfgt , .document-icon , .repotltbox .saverepo , .repotltbox .closerepo:hover'
    ]

    blueColorSelectors.forEach(selector => {
      console.log(selector)
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'color', color2);
    });

    //background-color: #5f73fc
    const blueBodyBackgroundColor = [
      ' .da_pgs.active , .der_pgs.active , .sec_pgw.active , .succes_cofbx h3:after , .succes_panel h2:after , .btn-primary , .lgqwrap',
      ' .dropdown-item:active',
      ' .theme-green .bs-datepicker-head , .theme-green .bs-datepicker-body table td span.selected ',
      ' .theme-green .bs-datepicker-body table td.selected span',
      ' .theme-green .bs-datepicker-body table td span[class*="select-"]::after',
      ' .theme-green .bs-datepicker-body table td[class*="select-"] span::after , .right_arr , .ratin',
      //'.date_pg .item.active , .date_pg .item:hover '
    ]
    blueBodyBackgroundColor.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'background-color', color2);
    });

    /**Lottie spinner color change */
    const lottieColorSelector = [
      '.splash-container .lottie-container ng-lottie g path'
    ]
    lottieColorSelector.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'fill', color2);
    });
    //.app-no-favourite/div/div/ng-lottie/div/svg/defs/linearGradient[4]/stop[1]

    //#__lottie_element_121 > stop:nth-child(1)
    //#__lottie_element_121 > stop:nth-child(2)
    //#__lottie_element_121 > stop:nth-child(3)
    const lottieStopColor = [
      '#__lottie_element_121 > stop:nth-child(1)',
      '#__lottie_element_121 > stop:nth-child(2)',
      '#__lottie_element_121 > stop:nth-child(3)',
    ]
    let lightColor = this.secondaryColour;
    lottieStopColor.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'stop-color', lightColor + ' ' + '!important');
      lightColor = this.shadeColor(lightColor, 80);
    });

    const lottieStopColor2 = [
      '#__lottie_element_120 > stop:nth-child(1)',
      '#__lottie_element_120 > stop:nth-child(2)',
      '#__lottie_element_120 > stop:nth-child(3)',
    ]
    let lightColor2 = this.secondaryColour;
    lottieStopColor2.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'stop-color', lightColor2 + ' ' + '!important');
      lightColor2 = this.shadeColor(lightColor2, 80);
    });



    //border-color: #6379FD
    const blueBorderColor = [
      '.log_sec_box .form-control , .res_canc_btn , .type-is-booking , .bre_updbtncan ',
      '.search_area .btn-primary , .login_section , .sec_pgw.active , .der_pgs:hover , .btn-primary , .da_pgs:hover',
      ' .log_section .log_img , .profile_cust_mgsec .customer .profic_coverbcg , .sec_pgw:hover ',
      ' .form-check-input:checked , .gate_waycover ul li.active a'
    ];
    blueBorderColor.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'border-color', color2);
    });

    const blueBorderBottom = [
      ' .visit_tabwrap .nav-pills .nav-link:after'
    ]
    blueBorderBottom.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'border-bottom-color', color2);
    });

    const blueStroke = [
      ' .date_deta_right .lottie-container ng-lottie div svg g g g g g path ',
      ' .date_deta_right .lottie-container ng-lottie  div  svg  g  g  g:nth-child(6)  g:nth-child(1)  path:nth-child(2) ',
      ' .date_deta_right .lottie-container  ng-lottie  div  svg  g  g  g:nth-child(6)  g:nth-child(2)  path:nth-child(2)',
      ' .grak_datebox .lottie-container ng-lottie div svg g g g g g path ',
      ' .grak_datebox .lottie-container ng-lottie  div  svg  g  g  g:nth-child(6)  g:nth-child(1)  path:nth-child(2) ',
      ' .grak_datebox .lottie-container  ng-lottie  div  svg  g  g  g:nth-child(6)  g:nth-child(2)  path:nth-child(2)',
      ' .profile_secs .lottie-container ng-lottie div svg g g g g g path ',
      ' .profile_secs .lottie-container ng-lottie  div  svg  g  g  g:nth-child(6)  g:nth-child(1)  path:nth-child(2) ',
      ' .profile_secs .lottie-container  ng-lottie  div  svg  g  g  g:nth-child(6)  g:nth-child(2)  path:nth-child(2)',
      ' .date_rec1 .lottie-container ng-lottie div svg g g g g g path ',
      ' .date_rec1 .lottie-container ng-lottie  div  svg  g  g  g:nth-child(6)  g:nth-child(1)  path:nth-child(2) ',
      ' .date_rec1 .lottie-container  ng-lottie  div  svg  g  g  g:nth-child(6)  g:nth-child(2)  path:nth-child(2)',
      '.notification-lottie ng-lottie div svg g g g path',
      ' ng-lottie  div  svg  g  g:nth-child(3)  g:nth-child(5)  path',
      ' ng-lottie  div  svg  g  g:nth-child(3)  g:nth-child(1)  path',
      ' ng-lottie  div  svg  g  g:nth-child(3)  g:nth-child(2)  path',
      ' ng-lottie  div  svg  g  g:nth-child(3)  g:nth-child(3)  path',
      ' ng-lottie  div  svg  g  g:nth-child(3)  g:nth-child(4)  path'
    ]

    blueStroke.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'stroke', color2);
    });

    const blueColorFill = [
      ' .cd_hecf ng-lottie  div  svg  g  g:nth-child(1)  g  path ',
      ' .notif_icons  ng-lottie  div  svg  g  g:nth-child(1)  g  path',
      '#secondary path'
    ]
    blueColorFill.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'fill', color2);
    });

    const redColorSelectors = [
      ' .content_left nav li a:hover', '.content_left nav li a.active , .wrap_date:after , .der_pgs h5 a ',
      '.type-is-queue , .image-edit-icon  , .sec_pgw.active:hover',
      ' .default-red-color , .d-r-c , .show_app_txt , .cust-letter-image ',
      ' .shedule_hrap , .pos_logoyrap h2 span , .editpersonal_vbcpg , .views_btns:hover',
      ' .profile_cust_mgsec li a i , .changeph_barmg , .savepersonal_vbcpg , .da_pgs  , .der_pgs h1 , .sec_pgw , .der_pgs ,.services_count a ',
      ' .view-vitals',
      '.plus_editpanfgt:hover , .download-icon , .repotltbox .saverepo:hover , .repotltbox .closerepo'
    ]
    redColorSelectors.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'color', color1);
    });


    const redColorFill = [
      ' .date_deta_right .lottie-container ng-lottie  div  svg  g  g  g:nth-child(14)  g:nth-child(2)  path',
      ' .date_deta_right .lottie-container ng-lottie  div  svg  g  g  g:nth-child(14)  g:nth-child(1)  path',
      ' .date_deta_right .lottie-container ng-lottie  div  svg  g  g  g:nth-child(15)  g:nth-child(2)  path',
      ' .date_deta_right .lottie-container ng-lottie  div  svg  g  g  g:nth-child(15)  g:nth-child(1)  path',
      ' .date_deta_right .lottie-container ng-lottie  div  svg  g  g  g:nth-child(16)  g:nth-child(2)  path',
      ' .date_deta_right .lottie-container ng-lottie  div  svg  g  g  g:nth-child(16)  g:nth-child(1)  path',
      ' .profile_secs .lottie-container ng-lottie  div  svg  g  g  g:nth-child(14)  g:nth-child(2)  path',
      ' .profile_secs .lottie-container ng-lottie  div  svg  g  g  g:nth-child(14)  g:nth-child(1)  path',
      ' .profile_secs .lottie-container ng-lottie  div  svg  g  g  g:nth-child(15)  g:nth-child(2)  path',
      ' .profile_secs .lottie-container ng-lottie  div  svg  g  g  g:nth-child(15)  g:nth-child(1)  path',
      ' .profile_secs .lottie-container ng-lottie  div  svg  g  g  g:nth-child(16)  g:nth-child(2)  path',
      ' .profile_secs .lottie-container ng-lottie  div  svg  g  g  g:nth-child(16)  g:nth-child(1)  path',
      ' .grak_datebox .lottie-container ng-lottie  div  svg  g  g  g:nth-child(14)  g:nth-child(2)  path',
      ' .grak_datebox .lottie-container ng-lottie  div  svg  g  g  g:nth-child(14)  g:nth-child(1)  path',
      ' .grak_datebox .lottie-container ng-lottie  div  svg  g  g  g:nth-child(15)  g:nth-child(2)  path',
      ' .grak_datebox .lottie-container ng-lottie  div  svg  g  g  g:nth-child(15)  g:nth-child(1)  path',
      ' .grak_datebox .lottie-container ng-lottie  div  svg  g  g  g:nth-child(16)  g:nth-child(2)  path',
      ' .grak_datebox .lottie-container ng-lottie  div  svg  g  g  g:nth-child(16)  g:nth-child(1)  path',
      ' .date_rec1 .lottie-container ng-lottie  div  svg  g  g  g:nth-child(14)  g:nth-child(2)  path',
      ' .date_rec1 .lottie-container ng-lottie  div  svg  g  g  g:nth-child(14)  g:nth-child(1)  path',
      ' .date_rec1 .lottie-container ng-lottie  div  svg  g  g  g:nth-child(15)  g:nth-child(2)  path',
      ' .date_rec1 .lottie-container ng-lottie  div  svg  g  g  g:nth-child(15)  g:nth-child(1)  path',
      ' .date_rec1 .lottie-container ng-lottie  div  svg  g  g  g:nth-child(16)  g:nth-child(2)  path',
      ' .date_rec1 .lottie-container ng-lottie  div  svg  g  g  g:nth-child(16)  g:nth-child(1)  path',
      '#primary path'
    ]

    redColorFill.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'fill', color1);
    });

    const redColorSvgFill = [
      //'.date_deta_left  ng-lottie  div  svg  g  g  g:nth-child(3)  image'
    ]

    redColorSvgFill.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'fill', color1);
    });

    //background: #798bf7
    const blueBackground = [
      '.log_secc_btn:before',
      '.change_btns:before',
      '.res_conf_btn:before', '.page-item.active .page-link',
      // '.conf_btn:before , .conf_btn',
      '.search_area .btn-primary ',
      '.res_conf_btn , .bre_updbtn:before',
      '.change_btns , .log_secc_btn , .update_email_btn , .update_email_btn , .bre_updbtn',
      '.owl-carousel .owl-nav button .owl-next:hover , .owl-carousel .owl-nav button .owl-prev:hover , .time_urc ',
      ' .wrap_date ::-webkit-scrollbar-thumb , .avail_head h2:after '

    ];
    blueBackground.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'background', color2);
    });



    //border-color: #FD4F43;
    const redBorderColor = [
      '.img_htyd  , .img_hgd , .img_adju',
      '.ui-widget-header',
      '.ui-state-highlight',
      '.ui-widget-content .ui-state-highlight',
      '.ui-widget-header .ui-state-highlight',
      '.ui-state-hover',
      '.ui-widget-content .ui-state-hover',
      '.ui-widget-header .ui-state-hover',
      '.ui-state-focus',
      '.ui-widget-content .ui-state-focus',
      '.ui-widget-header .ui-state-focus',
      '.da_pgs:hover',
      ' .type-is-queue , .der_pgs.active',
      ' .cont_notifs .image-border ',
      ' .red_circrack .profic_coverbcg , .btn-danger',
      ' .consultant .profic_coverbcg , .book_menus li .selected , .confirmimgbox_inntab'
    ]
    redBorderColor.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'border-color', color1);
    });

    //background-color: #FF7369
    const redBackgroundColor = [
      '.float_body , .modal-header , .date_pg .item.active , .date_pg .item:hover , .res_canc_btn:hover',
      ' .btn-danger , .pay_later , .profile_bs:before , .not-available-days'
    ]
    redBackgroundColor.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'background-color', color1);
    });



    //background: #FD4F43
    const redBackground = [
      '.ui-widget-header , .content_left nav li a.active , .content_left nav li a:hover ,  .lds-ellipsis div , .next_button , .book_menus li .selected::after',
      '.conf_btn:before , .conf_btn ',
      '.ui-state-highlight ,',
      '.ui-widget-content .ui-state-highlight',
      '.ui-widget-header .ui-state-highlight',
      '.ui-state-hover',
      '.ui-widget-content .ui-state-hover',
      '.ui-widget-header .ui-state-hover',
      '.ui-state-focus',
      '.ui-widget-content .ui-state-focus',
      '.ui-widget-header .ui-state-focus ',
      '.owl-carousel .owl-nav .owl-next:hover',
      '.owl-carousel .owl-nav .owl-prev:hover',



    ];

    redBackground.forEach(selector => {
      this.changeStylesheetRule(customerThemeStyleSheet, selector, 'background', color1);
    });

  }

  /**
   * Function to change style sheet rule
   * @param stylesheet 
   * @param selector 
   * @param property 
   * @param value 
   */
  // changeStylesheetRule(stylesheet, selector, property, value) {
  //   const s = this.document.styleSheets[3];
  //   selector = selector.toLowerCase();
  //   property = property.toLowerCase();
  //   value = value.toLowerCase();

  //   for (var i = 0; i < s.cssRules.length; i++) {
  //     var rule = s.cssRules[i];
  //     if (rule.selectorText === selector) {
  //       // console.log(rule.selectorText);
  //       // console.log(selector);
  //       rule.style[property] = value;
  //       return;
  //     }
  //   }
  //   stylesheet.insertRule(selector + " { " + property + ": " + value + "; }", 0);
  // }
  changeStylesheetRule(stylesheet, selector, property, value) {

    //setTimeout(()=>{
    const s = this.document.styleSheets[4];
    // console.log("STYLEs",s) 
    selector = selector.toLowerCase();
    property = property.toLowerCase();
    value = value.toLowerCase();

    for (var i = 0; i < s.cssRules.length; i++) {
      var rule = s.cssRules[i];
      if (rule.selectorText === selector) {
        rule.style[property] = value;
        return;
      }
    }
    //},200)
    //stylesheet.insertRule(selector + " { " + property + ": " + value + "; }", 0);
    const index = stylesheet.insertRule(selector + " { " + property + ": " + value + "; }", 0);
    this.stylePreviousIndex.push(index)
  }


  shadeColor(color, percent) {

    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt(`${R * (100 + percent) / 100}`);
    G = parseInt(`${G * (100 + percent) / 100}`);
    B = parseInt(`${B * (100 + percent) / 100}`);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));
    console.log('shadeColor', "#" + RR + GG + BB);
    return "#" + RR + GG + BB;
  }

}
