
<div class="alert-box" #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">Unsaved changes</h4>
    </div>
    <div class="modal-body">
        You have made changes, do you really want to go back?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="onConfirm()">Yes I'm sure</button>
        <button type="button" class="btn btn-primary" (click)="onCancel()">No</button>        
    </div>
</div>