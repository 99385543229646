import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BusinessService } from './business.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessResolveService implements Resolve<any> {

  constructor(private businessService: BusinessService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | boolean {
    return this.businessService.validateSubdomain();
  }
}
