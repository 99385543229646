import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundPage404Component } from './not-found-page404.component';



@NgModule({
  declarations: [NotFoundPage404Component],
  imports: [
    CommonModule
  ],
  exports: [NotFoundPage404Component]
})
export class NotFoundPage404Module { }
