import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-booking-review-confirmation',
  templateUrl: './booking-review-confirmation.component.html',
  styleUrls: ['./booking-review-confirmation.component.css']
})
export class BookingReviewConfirmationComponent implements OnInit {

  constructor(
    private _bsModalRef:BsModalRef, 
    private modalService:BsModalService,
    private router:Router) { }

  public onClose: Subject<boolean>;


  @ViewChild('confirmation') confirmationTemplate:TemplateRef<any>

  confirmationRef:BsModalRef

  ngOnInit(): void {
    this.onClose = new Subject();
  }

    public onConfirm(): void {
      this.onClose.next(true);
      this._bsModalRef.hide();
    }

  public onCancel(): void {
      this.onClose.next(false);
      this._bsModalRef.hide();
  }


  openModalConfirmation(){
    this.confirmationRef = this.modalService.show(this.confirmationTemplate,{class:'position-relative'})
  }
  
}
