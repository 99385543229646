import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	private timeZone = '';

	constructor(private httpClient: HttpClient) {
		const timezoneObj = new Date().toString().split('GMT')[1].split(' (')[0];
		this.timeZone = timezoneObj.substring(0, 3) + ':' + timezoneObj.substring(3, timezoneObj.length);
	}

	private setHeaders(): HttpHeaders {
		const headersConfig = {
			'Content-Type': 'application/json',
		};

		if (localStorage.getItem('b_id')) {
			headersConfig['customerbusiness_id'] = localStorage.getItem('b_id');
		}

		if (localStorage.getItem('c_id')) {
			headersConfig['customer_id'] = localStorage.getItem('c_id');
		}

		if (localStorage.getItem('CustomerAuthorization')) {
			headersConfig['Authorization'] = localStorage.getItem('CustomerAuthorization');
		}

		if (localStorage.getItem('request_handler_key')) {
			headersConfig['request_handler_key'] = localStorage.getItem('request_handler_key');
		}

		headersConfig['timezoneoffset'] = localStorage.getItem('businessTimeZone') ? localStorage.getItem('businessTimeZone') : '';

		return new HttpHeaders(headersConfig);
	}

	/**
	 * Function to set header to the form data header
	 */
	private setFormDataHaders(): HttpHeaders {
		const headersConfig = {
			// 'Content-Type': 'multipart/form-data',
		};
		if (localStorage.getItem('b_id')) {
			headersConfig['customerbusiness_id'] = localStorage.getItem('b_id');
		}

		if (localStorage.getItem('c_id')) {
			headersConfig['customer_id'] = localStorage.getItem('c_id');
		}

		if (localStorage.getItem('CustomerAuthorization')) {
			headersConfig['Authorization'] = localStorage.getItem('CustomerAuthorization');
		}

		if (localStorage.getItem('request_handler_key')) {
			headersConfig['request_handler_key'] = localStorage.getItem('request_handler_key');
		}

		headersConfig['timezoneoffset'] = localStorage.getItem('businessTimeZone') ? localStorage.getItem('businessTimeZone') : '';

		return new HttpHeaders(headersConfig);
	}

	private handleError(errorResponse: HttpErrorResponse) {
		if (errorResponse.error instanceof ErrorEvent) {
			// console.error('Client Side Error: ', errorResponse.error.message);
		} else {
			// console.error('Server Side Error: ', errorResponse);
		}
		console.log(errorResponse.status);
		console.log(errorResponse.status == 403);
		if (errorResponse.status == 403 || errorResponse.status == 401) {
			//1
			localStorage.removeItem('c_id');
			localStorage.removeItem('CustomerAuthorization');
			localStorage.removeItem('request_handler_key');
			localStorage.removeItem('customer_credentials');

			window.location.replace('/');
		}
		return throwError(errorResponse.error);
	}

	public post(path: String, body: any): Observable<any> {
		const param = JSON.stringify(body);
		return this.httpClient.post(`${environment.backend}${path}`, param, { headers: this.setHeaders() }).pipe(
			// retry(3),
			catchError(this.handleError)
		);
	}
	public get(path: string, param: HttpParams = new HttpParams()): Observable<any> {
		return this.httpClient.get(`${environment.backend}${path}`, { headers: this.setHeaders(), params: param }).pipe(
			// retry(3),
			catchError(this.handleError)
		);
	}
	public postData(path: string, body): Observable<any> {
		return this.httpClient.post(environment.backend + path, body, { headers: this.setFormDataHaders() }).pipe(
			// retry(3),
			catchError(this.handleError)
			// map((res:Response)=>res.json)
		);
	}
}
