import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();

	// #region disable console debugging methods

	if (window) {
		if (!window.console) {
			window.console = <any>{};
		}

		const methods: any[] = ['log', 'debug', 'warn', 'info'];

		for (let i = 0; i < methods.length; i++) {
			console[methods[i]] = function (): void {};
		}
	}

	// #endregion
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
