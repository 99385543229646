import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-module-loader',
  templateUrl: './module-loader.component.html',
  styleUrls: ['./module-loader.component.css']
})
export class ModuleLoaderComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('videoPlayer') videoplayer: ElementRef;

  private interval: any;

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.interval = setInterval(() => {
      // this.videoplayer.nativeElement.play();
      const media = this.videoplayer.nativeElement;
      media.muted = true; // without this line it's not working although I have "muted" in HTML
      media.play();
    }, 200);

  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

}
