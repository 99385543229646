import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerBusinessNameAuthGuard } from './services/customer-auth.guard';
import { BusinessResolveService } from './services/business-resolve.service';
import { CusomerResolverService, CustomerBusinessResolver } from './services/cusomer-resolver.service';
import { NotFoundPage404Component } from './Admin-module/app/not-found-page404/not-found-page404.component';
import { environment } from 'src/environments/environment';


let isAdminPath = false;
let isCustomerPath = false;

let slugName = '';

const hostName = window.location.hostname;
// let hostName = 'admin-ywait.ywait.in/select-business';


if (hostName != 'localhost' && hostName != '127.0.0.1' && hostName != '207.38.85.230') {
  let array = hostName.split('.');
  if (array[0] == 'www') {
    slugName = array[1];
  } else {
    slugName = array[0];
  }
} else {
  slugName = environment.slugName;
}


// const isAdminIndex = slugName.search('admin-');

const locationHref = window.location.href


const isAdminIndex = locationHref.search('/admin');


if (isAdminIndex == -1) {
  isCustomerPath = true;
  isAdminPath = false;
} else {
  isCustomerPath = false;
  isAdminPath = true;
}

/***For Dev**/
/**Enable customer routes**/
// isCustomerPath = true;
// isAdminPath = false;

/**Enable admin roues */
// isCustomerPath = false;
// isAdminPath = true;

let routes: Routes = [];
if (isAdminPath) {  //Admin 
  routes = [
    {
      // path: '',
      path: 'admin',
      loadChildren: () => import('./Admin-module/app/app.module').then(m => m.AppModule)
    },
    {
      path: '**',
      redirectTo: '/404'
    }

  ];
} else {  //Customer
  routes = [
    {
      path: 'country-help',
      loadChildren: () => import('./country-state-help/country-state-help.module').then(m => m.CountryStateHelpModule)
    },
    {
      path: 'select-business',
      loadChildren: () => import('./business-selection/business-selection.module').then(m => m.BusinessSelectionModule)
    },
    {
      path: '404',
      component: NotFoundPage404Component
    },
    {
      path: '',
      canActivate: [CustomerBusinessNameAuthGuard],
      resolve: {
        'businessDetails': BusinessResolveService,
        // 'customerDetails': CusomerResolverService,
        // 'customerBusinessDetails': CustomerBusinessResolver
      },
      children: [
        {
          path: 'authentication',
          loadChildren: () => import('./Authentication/authentication.module').then(m => m.AuthenticationModule)
        },
        {
          path: 'booking-ticket/:type/:bookingId',
          loadChildren: () => import('./booking-ticket/booking-ticket.module')
            .then(m => m.BookingTicketModule)
        },
        {
          //Customer home layout module
          path: '',
          loadChildren: () => import('./customer-home-layout/customer-home-layout.module').then(m => m.CustomerHomeLayoutModule)
        }
      ]
    },
    {
      path: '**',
      redirectTo: '/404'
    }
  ];
}

// const routes: Routes = [
//   {
//     path: 'select-business',
//     loadChildren: () => import('./business-selection/business-selection.module').then(m => m.BusinessSelectionModule)
//   },
//   /**Customer path */
//   {
//     path: '',
//     canActivate: [CustomerBusinessNameAuthGuard],
//     resolve: {
//       'businessDetails': BusinessResolveService,
//       // 'customerDetails': CusomerResolverService,
//       // 'customerBusinessDetails': CustomerBusinessResolver
//     },
//     children: [
//       {
//         path: 'authentication',
//         loadChildren: () => import('./Authentication/authentication.module').then(m => m.AuthenticationModule)
//       },
//       {
//         //Customer home layout module
//         path: '',
//         loadChildren: () => import('./customer-home-layout/customer-home-layout.module').then(m => m.CustomerHomeLayoutModule)
//       }
//     ]
//   },

//   /**Admin path */
//   {
//     path: 'admin',
//     loadChildren: () => import('./Admin-module/app/app.module').then(m => m.AppModule)
//   },

//   {
//     path: '404',
//     component: NotFoundPage404Component
//   },
//   {
//     path: '**',
//     redirectTo: '/404'
//   }
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
