import { version } from '../helper/version';

export const environment = {
	production: false,
	isInstaBusiness: false,

	isVersion2ApiEnabled: true,
	/**Qa ywaitapp.com-2001 */
	// backend: 'https://ywaitapp.com:2001/web',
	// apiUrl: 'https://ywaitapp.com:2001/web',

	backend: 'https://y-wait.com:2001/v2/web', //New base url 13/12/2021
	// backendV2: 'https://y-wait.com:2001/v2/web', // V2 Backend URL
	apiUrl: 'https://y-wait.com:2001/web',
	baseApiUrl: 'https://y-wait.com:2001/v2',

	// backend: 'https://ywait.in:2019/web', // staging
	// apiUrl: 'https://ywait.in:2019/web',
	// backend: 'https://ywait.org:8881/web', // ywait demo prod
	// apiUrl: 'https://ywait.org:8881/web',

	/**Qa-New ywaitapp.com-2005 */
	// backend: 'https://ywaitapp.com:2005/web',
	// apiUrl: 'https://ywaitapp.com:2005/web',

	//Staging test 2006
	// backend: 'https://ywaitapp.com:2006/web', // Prod demo for testing
	// apiUrl: 'https://ywaitapp.com:2006/web', // Prod demo for testing

	//Staging test 2007
	// backend: 'https://ywaitapp.com:2007/web', // Staging server - test env
	// apiUrl: 'https://ywaitapp.com:2007/web', // Staging server - test env

	facebookAppId: '220012035964479', //jens //Facebook id
	googleClientId: '854502397781-sie0g3oecg848gi6trl1b3olo2ttga1c.apps.googleusercontent.com',
	// slugName: 'Ywait',
	// slugName: 'sunrise',
	// slugName: 'medhope',
	// slugName:'al-noor-polyclinic',
	//slugName:'omh',
	// slugName:'ywait',
	// slugName: 'ywait-spa',
	// slugName:'omh',
	// slugName:'demo',
	slugName: 'gcc-demo',
	// slugName: 'i-g-d',
	//baseDomain: '.ywaitapp.com/',
	baseDomain: '.y-wait.com/',

	version: version.version,
};
