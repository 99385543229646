import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingReviewConfirmationComponent } from './booking-review-confirmation.component';
import { ModalModule } from 'ngx-bootstrap/modal';


@NgModule({
    declarations: [BookingReviewConfirmationComponent],
    imports: [
        CommonModule,
        ModalModule.forRoot()
    ],
    exports: [BookingReviewConfirmationComponent]
})
export class BookingReviewConfirmationModule { }
