import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BusinessService } from './business.service';
// import { AdminTimeZoneService, TimezoneOffsetService } from './admin-time-zone.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private timeZone = '';

  constructor(
    private httpClient: HttpClient // private businessService:BusinessService, // private timezoneService:AdminTimeZoneService, // private TimezoneOffsetService: TimezoneOffsetService
  ) {
    const timezoneObj = new Date().toString().split('GMT')[1].split(' (')[0];
    this.timeZone =
      timezoneObj.substring(0, 3) +
      ':' +
      timezoneObj.substring(3, timezoneObj.length);
  }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
    };

    if (localStorage.getItem('business_id')) {
      headersConfig['business_id'] = localStorage.getItem('business_id');
    }

    if (localStorage.getItem('user_id')) {
      headersConfig['user_id'] = localStorage.getItem('user_id');
    }

    if (localStorage.getItem('Authorization')) {
      headersConfig['Authorization'] = localStorage.getItem('Authorization');
    }

    headersConfig['timezoneoffset'] = localStorage.getItem('businessTimeZone')
      ? localStorage.getItem('businessTimeZone')
      : '';

    return new HttpHeaders(headersConfig);
  }

  /**
   * Function to set header to the form data header
   */
  private setFormDataHaders(): HttpHeaders {
    const headersConfig = {
      // 'Content-Type': 'multipart/form-data',
    };
    if (localStorage.getItem('business_id')) {
      headersConfig['business_id'] = localStorage.getItem('business_id');
    }

    if (localStorage.getItem('user_id')) {
      headersConfig['user_id'] = localStorage.getItem('user_id');
    }

    if (localStorage.getItem('Authorization')) {
      headersConfig['Authorization'] = localStorage.getItem('Authorization');
    }

    headersConfig['timezoneoffset'] = localStorage.getItem('businessTimeZone')
      ? localStorage.getItem('businessTimeZone')
      : '';

    return new HttpHeaders(headersConfig);
  }

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      // console.error('Client Side Error: ', errorResponse.error.message);
    } else {
      // console.error('Server Side Error: ', errorResponse);
    }
    console.warn(errorResponse.status);
    if (errorResponse.status == 403 || errorResponse.status == 401) {
      //1
      localStorage.removeItem('user_id');
      localStorage.removeItem('Authorization');
      window.location.replace('/admin/login');
    }
    return throwError(errorResponse.error);
  }

  public post(path: String, body: any): Observable<any> {
    const param = JSON.stringify(body);
    return this.httpClient
      .post(`${environment.backend}${path}`, param, {
        headers: this.setHeaders(),
      })
      .pipe(
        // retry(3),
        catchError(this.handleError)
      );
  }
  public get(
    path: string,
    param: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.httpClient
      .get(`${environment.backend}${path}`, {
        headers: this.setHeaders(),
        params: param,
      })
      .pipe(
        // retry(3),
        catchError(this.handleError)
      );
  }
  public put(path: string, body: any): Observable<any> {
    return this.httpClient
      .put(`${environment.backend}${path}`, body, {
        headers: this.setHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  public customPut(path: string, body: any): Observable<any> {
    return this.httpClient
      .put(`${environment.baseApiUrl}${path}`, body, {
        headers: this.setHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  public postData(path: string, body): Observable<any> {
    return this.httpClient
      .post(environment.backend + path, body, {
        headers: this.setFormDataHaders(),
      })
      .pipe(
        // retry(3),
        catchError(this.handleError)
        // map((res:Response)=>res.json)
      );
  }
  public putData(path: String, body: any): Observable<any> {
		return this.httpClient
			.put(`${environment.backend}${path}`, body, {
				headers: this.setFormDataHaders(),
			})
			.pipe(catchError(this.handleError));
	}

  public postDataWithProgress(path: string, body): Observable<any> {
    return this.httpClient
      .post(environment.backend + path, body, {
        headers: this.setFormDataHaders(),
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        // retry(3),
        catchError(this.handleError)
        // map((res:Response)=>res.json)
      );
  }

  /**
   * Created By Sree chand
   * 28 Jun 2021
   * Function to download a file
   * @param link Download link
   */
  public downloadFile(link: string) {
    return this.httpClient
      .get(link, {
        responseType: 'blob',
        // observe: 'response',
        headers: this.setHeadersForFileDownload(),
      })
      .pipe(catchError(this.handleError));
  }

  private setHeadersForFileDownload(): HttpHeaders {
    const headersConfig = {
      // 'Access-Control-Allow-Origin':'*'
    };
    return new HttpHeaders(headersConfig);
  }
  public customPathPutRequest(path: string, body: any): Observable<any> {
    return this.httpClient
      .put(`${environment.baseApiUrl}${path}`, body, {
        headers: this.setHeaders(),
      })
      .pipe(catchError(this.handleError));
  }
  public getValid(
    path: string,
    param: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.httpClient
      .get(`${environment.apiUrl}${path}`, {
        headers: this.setHeaders(),
        params: param,
      })
      .pipe(
        // retry(3),
        catchError(this.handleError)
      );
  }
}
