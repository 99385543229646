import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggedInUserService {

  private loggedInUserSubject = new BehaviorSubject(null);
  public loggedInUser$ = this.loggedInUserSubject.asObservable();

  constructor() { }


  /**
   * Function to set logged in user data as observable
   * @param data user data
   */
  public setLoggedInUserData(data) {
    this.loggedInUserSubject.next(data);
  }

  public getLoggedInUser() {
    return this.loggedInUserSubject.value;
  }

  /**Return logged in user details */
  public get userDetails() {
    return this.loggedInUserSubject.value;
  }

}
