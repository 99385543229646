import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { GeneralService } from './general.service';
import { BusinessType } from '../Models/businessType.model';
import { MenuService } from '../Admin-module/app/Services/menu.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { InternetConnectionTestService } from './internet-connection-test.service';

@Injectable({
	providedIn: 'root',
})
export class BusinessService {
	/**12 Hour time format */
	private twelveHourTimeFormat = 'hh:mm a';

	/**24 Hour time format */
	private twentyHourTimeFormat = 'HH:mm';

	private businessDetailSubject = new BehaviorSubject(null);
	public businessDetail = this.businessDetailSubject.asObservable();

	constructor(private apiService: ApiService, private generalService: GeneralService, private menuService: MenuService, private router: Router, private internetConnectionService: InternetConnectionTestService, @Inject(DOCUMENT) private document) {}

	/**
	 *
	 * @param data business detail
	 */
	setBusinessDetail(data) {
		this.businessDetailSubject.next(data);
	}

	/**
	 * Return business detail of current business
	 */
	getBusinessDetails() {
		return this.businessDetailSubject.value;
	}

	/**
	 * Function to return time zone of a business
	 */
	getTimeFormat() {
		const businessDetails = this.getBusinessDetails();
		if (businessDetails && businessDetails.timeFormat) {
			return businessDetails.timeFormat;
		} else {
			return '12';
		}
	}

	/**
	 * Function to return time format from business settings
	 */
	getTimeFormatPipe() {
		if (this.getTimeFormat() == '12') {
			return this.twelveHourTimeFormat;
		} else {
			return this.twentyHourTimeFormat;
		}
	}

	/**
	 * Function to return specialist name from business settings
	 */
	getSpecialistName() {
		if (this.businessDetailSubject.value && this.businessDetailSubject.value.specialistName) {
			return this.businessDetailSubject.value.specialistName;
		} else {
			return 'Consultants';
		}
	}

	/**
	 * Function return type of business
	 * 1. Check if businessType is available in business settings
	 * 2. Else return null
	 * single-consultant , multiple-service-single-consultant , multiple-service-multiple-consultant , multiple-consultant
	 */
	getBusinessType(): BusinessType {
		let type: BusinessType = null;
		if (this.getBusinessDetails().businessType) {
			//1
			type = this.getBusinessDetails().businessType;
		}
		return type;
		// return 'single-consultant'
	}

	/**
	 * Function to validate subdomain.
	 */
	validateSubdomain(): any {
		console.log('validateSubdomain()');
		const url = '/public/validate/slugname?sub_domain=' + this.generalService.getSubDomain();
		const data = this.apiService.get(url);
		let promise;
		return (promise = new Promise((resolve, reject) => {
			data.subscribe(
				(res) => {
					if (res.status) {
						if (res['data'].objects.enableCustomerLogin) {
							localStorage.setItem('b_id', res['data'].objects._id);
							this.setBusinessDetail(res['data'].objects);
							document.title = this.getBusinessDetails().name;
							if (this.getBusinessDetails().image) {
								this.document.getElementById('favicon-icons').setAttribute('href', this.getBusinessDetails().image);
							}
							resolve(res);
						} else {
							console.log('enableCustomerLogin is false . Navigating to 404 page');
							this.menuService.invalidSlugName = true;
							this.router.navigateByUrl('/404');
						}
					} else {
						this.menuService.invalidSlugName = true;
						if (res['message'] == 'permission denied invalid user') {
							localStorage.removeItem('c_id');
							localStorage.removeItem('CustomerAuthorization');
							localStorage.removeItem('request_handler_key');
							localStorage.removeItem('customer_credentials');
							this.router.navigateByUrl('/');
						} else {
							this.router.navigateByUrl('/404');
						}
					}
				},
				(err) => {
					console.log(err);
					this.internetConnectionService.checkInternetConnection(err);
				}
			);
		}));
	}

	/**
	 * Get new customer id from server
	 */
	getCustomerId() {
		const url = '/admin/new/customer/id/' + localStorage.getItem('b_id');
		return this.apiService.get(url);
	}

	/**
	 * Get current business patient info
	 */
	getBusinessPatientInfo() {
		const url = `/customer/fields/${localStorage.getItem('b_id')}`;
		return this.apiService.get(url);
	}

	/**
	 * Function to get all business
	 */
	getAllBusiness(page, size) {
		const url = `/customer/list/business/${page}/${size}`;
		return this.apiService.get(url);
	}
}
