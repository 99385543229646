import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {

  constructor() { }

  public showLoader = false;

  public loaderPosition: 'center' | 'right' = 'center';

  /**
   * Function to start loader
   */
  start(position: 'center' | 'right' = 'center') {
    this.loaderPosition = position;
    this.showLoader = true;
  }

  /**
   * Function to stop loader
   */
  stop() {
    this.showLoader = false;
    this.loaderPosition = "center";
  }

}
