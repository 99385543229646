import { Injectable, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { element } from 'protractor';
import { BusinessService } from './business.service';
import { GeneralService } from 'src/app/services/general.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Date:20/01/2021
 * Created:Sree chand
 * Color theme changer for admin app
 */
export class AdminThemeService {
  constructor(
    @Inject(DOCUMENT) private document,
    private businessService: BusinessService,
    private generalService: GeneralService
  ) {
    this.subToBusiness();
  }

  primaryColour = '';

  secondaryColour = '';

  stylePreviousIndex = [];

  /**
   * Date:20/01/2021
   * Created:Sree chand
   * Function to subscribe to business details
   */
  subToBusiness() {
    this.businessService.businessDetail.subscribe((res) => {
      //Observable subscription
      if (res) {
        const adminThemeStyleSheet = this.document.styleSheets[4];
        console.log(adminThemeStyleSheet);
        if (this.stylePreviousIndex.length > 0) {
          this.stylePreviousIndex.forEach((index) => {
            adminThemeStyleSheet.deleteRule(index);
          });
          this.stylePreviousIndex = [];
        }
        this.primaryColour = res.primaryColor ? res.primaryColor : '#FF4539';
        this.secondaryColour = res.secondaryColor
          ? res.secondaryColor
          : '#1C33A6';
        this.changeColorTheme(
          this.primaryColour + ' ' + '!important',
          this.secondaryColour + ' ' + '!important'
        );

        //this.previousIndex =[]
      }

      // this.changeColorTheme('#6379FD !important', '#FF5264 !important'); //c1 blue c2 red
      // this.changeColorTheme('#FF5264 !important', '#6379FD !important'); //c1 red c2 blue
      // this.changeColorTheme('#EBAE7D !important', '#C64466 !important'); //c1 yellow-orange c2 dark pink
      // this.changeColorTheme('#FBD412 !important', '#333751 !important'); //c1 yellow c2 violet
      // this.changeColorTheme('#FF9671 !important', '#F9F871 !important'); //c1 yellow c2 violet
    });
  }

  /**
   * Date:20/01/2021
   * Created:Sree chand
   * Function to change color theme of app
   * @param color1 primary color 1
   * @param color2 primary color 2
   */
  changeColorTheme(color1, color2) {
    const adminThemeStyleSheet = this.document.styleSheets[4];
    // const css = this.document.getElementsByClassName(`.beau_box,.dash_headcn:after,.depar_numb li span`);

    /* Red color*/
    const redColorSelectors = [
      '.no-pref-cont h3', //Custom css
      '.second_head .breadcrumb a:hover, .appoinment_scroll h1, .close_vn, .tody_appoint li .viewbt_nm, .profile_bs.thirdpr h5, .plus_ars span, .der_pgs h5 a, .inner_tab_pan_scroll .nav-link:hover, .inner_tab_pan_scroll .nav-link.active, .view_app_btn:hover, .add_breakbtn:hover, .main_lis_manage .nav-link:hover, .main_lis_manage .nav-link.active, .add_timedt:hover, .manage_table table tbody tr td a:hover, .i_btntooltip:hover, .cust_notes_box h5, .info_btnsec a:hover, .customer_listing table tbody tr td a:hover, .customer_listing li a:hover, .ywaiterror_page h3, .ywaiterror_page a:hover, .tody_appoint .card-header .btn:hover, .tody_appoint .card-header .btn, .profic_coverbcg, .changeph_barmg, .profile_cust_mgsec li a i, .editpersonal_vbcpg, .savepersonal_vbcpg, .ellipsis_prevbtn:hover, .plus_editpanfgt:hover, .save_breditse a, .newsave_personalbtn, .customize_vbcpg, .customer_listkbcv a:hover, .addcustom_btnlj, .next_noldbtn, .cancel_noldbtn, .editselectngk:hover, .missing_contbg i, .red_circrack .changeph_barmg:hover, .red_circrack .profile_cust_mgsec li a:hover i, .profile_buisnes_mgsec .changeph_barmg:hover, .availibility_ho:hover, .arrow_openwrp li a:hover i, .profile_manabx h3:hover, .reddtwrap, .addn_btn:hover, .ful_btn:hover, .viewa_btn:hover, .imbox_base.textjrw, .content_left nav li a.active',
      '.brkhistory a:hover , .imbox_base.textjrw , .customer-letter-image , .remove_logo:hover , .remove_logo , .customer .changeph_barmg',
      '.color-primary , .da_pgs , .der_pgs , .sec_pgw , .der_pgs h1 , .download_link:hover',
      '.re-order-btn , .schedule_next_visit a:hover , .next-visit_reminder a .schedule-next-visit-icons , .labelTooltip:hover , .sec_pgw span:hover',
      '.customer-font-color',
      // '.timerapogid li:first-child:after',
      '.document-container .download-icon , .view-vitals , .change-link a',
      '.calendar-queue-list .da_pgs:hover , .actions-icons .breakIcons:hover , .current-plan .plan-name:hover',
      '.icon-primary-color','.business-primary-c'
    ];
    redColorSelectors.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'color',
        color1
      );
    });


    const primarySvgFillSelectors = ['.svg-primary-color'];
    primarySvgFillSelectors.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'fill',
        color1
      );
    });
    /*Blue colour */
    const blueColorSelectors = [
      '.notif_icons a:hover, .log_section .dropdown-item:hover, .log_section .dropdown-item:focus, .second_head .breadcrumb a, .resolve_sec li i, .det_mangr h3, .logo span, .weather_box h2 i, .over_rating h4 span, .tody_appoint li .viewbt_nm:hover, .close_vn:hover, .scroll_plus:hover .plus_ars.adj_plus span, .dash_headcn, .date_boxpg .nav-link.active, .date_boxpg .nav-link:hover, .der_pgs h6 a, .tok_app_txt, .view_app_btn, .view_appo_boxs span, .card_bxr, .card_bxr:hover, .set_head_man span, .add_breakbtn, .add_timedt, .manage_table table tbody td.blue_clr, .manage_table table tbody tr td a, .i_btntooltip, .notes_section h5 span, .que_serbox li.active, .notes_section h5.note_histoty, .cust_notes_box h3, .info_btnsec a, .customer_listing table tbody tr td a, .customer_listing li a, .ywaiterror_page h1, .ywaiterror_page a, .tody_appoint .card-header .btn.collapsed, .profile_cust_mgsec li a:hover i, .changeph_barmg:hover, .personal_view_bcpg table tr td:last-child, .editpersonal_vbcpg:hover, .savepersonal_vbcpg:hover, .ellipsis_prevbtn .dropdown-item:hover, .plus_editpanfgt, .save_breditse a:hover, .profile_csp.use_rabx h6, .newsave_personalbtn:hover, .idbook_headtr span, .complete_hdareas span, .time_days span:first-child:after, .time_days span:last-child:after, .customize_vbcpg:hover, .customer_listkbcv a, .addcustom_btnlj:hover, .minute_tx, .menu_gthhead, .next_noldbtn:hover, .cancel_noldbtn:hover, .editselectngk, .red_circrack .changeph_barmg, .red_circrack .profile_cust_mgsec li a i, .profile_buisnes_mgsec .changeph_barmg, .arrow_openwrp li a i, .bluedtwrap, .time_lapse i, .addn_btn, .ful_btn, .viewa_btn, .eye_imgsec.passwrap a, .users_heads.genwrapds, .content_left nav li a:hover',
      '.content_left nav li a:hover,.content_left nav li a.active , .authen_tabsec a',
      '.notif_icons a:hover, .log_section .dropdown-item:hover',
      '.log_section .dropdown-item:focus, .second_head .breadcrumb a',
      '.resolve_sec li i, .det_mangr h3',
      '.logo span',
      '.weather_box h2 i, .over_rating h4 span',
      '.tody_appoint li .viewbt_nm:hover',
      '.close_vn:hover, .scroll_plus:hover .plus_ars.adj_plus span',
      '.dash_headcn, .date_boxpg .nav-link.active,.date_boxpg .nav-link:hover',
      '.der_pgs h6 a',
      '.tok_app_txt',
      '.view_app_btn',
      '.view_appo_boxs span',
      '.card_bxr, .card_bxr:hover ,.set_head_man span',
      '.add_breakbtn',
      '.add_timedt',
      '.manage_table table tbody td.blue_clr, .manage_table table tbody tr td a',
      '.i_btntooltip',
      '.notes_section h5 span',
      '.que_serbox li.active',
      '.notes_section h5.note_histoty',
      '.cust_notes_box h3',
      '.info_btnsec a',
      '.customer_listing table tbody tr td a',
      '.customer_listing li a',
      '.tody_appoint .card-header .btn.collapsed',
      '.profile_cust_mgsec li a:hover i',
      '.changeph_barmg:hover',
      '.personal_view_bcpg table tr td:last-child',
      '.editpersonal_vbcpg:hover , .savepersonal_vbcpg:hover',
      '.ellipsis_prevbtn .dropdown-item:hover, .plus_editpanfgt',
      '.save_breditse a:hover, .profile_csp.use_rabx h6,.newsave_personalbtn:hover',
      '.idbook_headtr span',
      '.complete_hdareas span',
      '.time_days span:first-child:after',
      '.time_days span:last-child:after , .customize_vbcpg:hover',
      '.customer_listkbcv a,.addcustom_btnlj:hover, .minute_tx',
      '.menu_gthhead ,.next_noldbtn:hover,.cancel_noldbtn:hover , .der_pgs:hover , .der_pgs:hover h1',
      '.editselectngk, .red_circrack .changeph_barmg,.red_circrack .profile_cust_mgsec li a i',
      '.profile_buisnes_mgsec .changeph_barmg , .arrow_openwrp li a i',
      '.bluedtwrap',
      '.time_lapse i , .addn_btn',
      '.ful_btn , .viewa_btn',
      '.no-const-img , .sec_pgw:hover',
      '.content_left nav li a:hover, .content_left nav li a.active',
      '.no-cons-image',
      '.mornin_dt span:first-child:after, .mornin_update span:first-child:after, .mornin_dt span:last-child:after, .mornin_update span:last-child:after',
      '.ywaiterror_page h1, .ywaiterror_page a , .btn-link , .brkhistory a , .alert a',
      '.confirm-btn-container .btn-outline-secondary , .res_canc_btn , .da_pgs:hover , .bre_updbtncan ',
      ' .tok_app_txt , .page-link , .or_wrd , .base_url , .changeph_barmg ',
      '.content_left nav li a:hover, .content_left nav li a.active, .notif_icons a:hover, .log_section .dropdown-item:hover, .log_section .dropdown-item:focus, .second_head .breadcrumb a, .resolve_sec li i, .det_mangr h3, .logo span, .weather_box h2 i, .over_rating h4 span, .tody_appoint li .viewbt_nm:hover, .close_vn:hover, .scroll_plus:hover .plus_ars.adj_plus span, .dash_headcn, .date_boxpg .nav-link.active, .date_boxpg .nav-link:hover, .der_pgs h6 a, .tok_app_txt, .view_app_btn, .view_appo_boxs span, .card_bxr, .card_bxr:hover, .set_head_man span, .add_breakbtn, .add_timedt, .manage_table table tbody td.blue_clr, .manage_table table tbody tr td a, .i_btntooltip, .notes_section h5 span, .que_serbox li.active, .notes_section h5.note_histoty, .cust_notes_box h3, .info_btnsec a, .customer_listing table tbody tr td a, .customer_listing li a, .ywaiterror_page h1, .ywaiterror_page a, .tody_appoint .card-header .btn.collapsed, .profile_cust_mgsec li a:hover i, .changeph_barmg:hover, .personal_view_bcpg table tr td:last-child, .editpersonal_vbcpg:hover, .savepersonal_vbcpg:hover, .ellipsis_prevbtn .dropdown-item:hover, .plus_editpanfgt, .save_breditse a:hover, .profile_csp.use_rabx h6, .newsave_personalbtn:hover, .idbook_headtr span, .complete_hdareas span, .time_days span:first-child:after, .time_days span:last-child:after, .customize_vbcpg:hover, .customer_listkbcv a, .addcustom_btnlj:hover, .minute_tx, .menu_gthhead, .next_noldbtn:hover, .cancel_noldbtn:hover, .editselectngk, .red_circrack .changeph_barmg, .red_circrack .profile_cust_mgsec li a i, .profile_buisnes_mgsec .changeph_barmg, .arrow_openwrp li a i, .bluedtwrap, .time_lapse i, .addn_btn, .ful_btn, .viewa_btn, .eye_imgsec.passwrap a, .users_heads.genwrapds',
      //' .der_pgs:hover p'
      '.calendar-queue-list .da_pgs',
      ' .da_pgs.disabled , .total_amount',
      '.mat-icon.mat-primary , .consultant-letter-image',
      'table tbody .table-heading , .logsec_logo h3 , .backButton , .changeColor:hover , .download_link , .re-order-btn:hover , .schedule_next_visit a , .next-visit_reminder a:hover .schedule-next-visit-icons , .labelTooltip , .appointment-detail-status , .sec_pgw a , .renew-link a',
      '.timerapogid li:first-child:after , .view-vitals:hover , .document-icon , .actions-icons .breakIcons , .current-plan .plan-name , .gateway-list ul li a.active i',
      ' .expiry-date-text','.blue-text'
      // '.calendar-queue-list .da_pgs:hover'
    ];

    blueColorSelectors.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'color',
        color2
      );
    });

    /**Svg fill color 1 (red) */
    const svgFillColor1Selector = [
      '.genimgwrap .genimgwrapg',
      '.fill-primary',
      '.service-department-lottie ng-lottie div svg g g:nth-child(2) g g:last-child path',
      '.negativeScreen svg path:nth-child(26)',
      '#primary path',
      '.lo-container [fill="rgb(255,69,57)"]',
    ];
    svgFillColor1Selector.forEach((selector) => {
      this.changeStylesheetRule(adminThemeStyleSheet, selector, 'fill', color1);
    });

    /**Svg fill color 2 (blue) */
    const svgFillColor2Selector = [
      /**Lottie spinner color change */
      '.splash-container .lottie-container ng-lottie g path',
      '.fill-secondary',
      '.genimgwrap .genimgwrapd',
      '.negativeScreen #Path_3189',
      '.negativeScreen #Path_3190',
      '.negativeScreen #Path_3193',
      '.no_vists .c,.noConsultant-svg .e',
      '.no-app-svg .e, .no-app-svg .h , .no-appointment-svg-calender .e  , .no-appointment-svg-calender .h',
      '.service-department-lottie ng-lottie div svg g g:last-child g g:first-child path',
      '.no-booking-lottie ng-lottie div svg g g g:nth-child(3) path',
      // '.noConsultant-svg .e',
      '.noConsultant-svg svg g path:first-child',
      // '.noConsultant-svg svg g path:nth-child(26)',
      '#secondary path ',
      '.lo-container [fill="rgb(28,51,166)"]',
    ];
    svgFillColor2Selector.forEach((selector) => {
      this.changeStylesheetRule(adminThemeStyleSheet, selector, 'fill', color2);
    });

    const borderLeftColor = ['.consul_box .profile_bs:after'];
    borderLeftColor.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'border-left-color',
        color1
      );
    });

    const borderTopColor = ['.consul_box .profile_bs:after'];
    borderLeftColor.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'border-top-color',
        color1
      );
    });

    /**Svg stroke  */
    const svgStroke2Selector = [
      '.notification-lottie ng-lottie div svg g g g path',
      '.no-booking-lottie ng-lottie div svg g g path',
      '.stroke-secondary',
      '#lottie-loader-id > ng-lottie > div > svg > g > g > g > g > path',
    ];
    svgStroke2Selector.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'stroke',
        color2
      );
    });

    /*Blue background */
    const blueBackgroundSelector = [
      '.owl-carousel .owl-nav button.owl-next:hover, .owl-carousel .owl-nav button.owl-prev:hover, .search_area .btn-primary, .cons_scroll li a:hover, .date_calender_tab.bookpg .item.active, .date_calender_tab .item:hover, .date_calender_tab.upcomingpg .item.active, .date_calender_tab.previouspg .item.active, .custom-control-input:checked ~ .custom-control-label::before',
      '.custom_btn_booking',
      '.custom_btn_availability , .bre_updbtn , .cosult_boxbracwrap ::-webkit-scrollbar-thumb',
      '.confirm-btn-container .btn-success , .addvistbnbtn , .nxt_bt , .lat_permistabbg input:disabled , .owl-theme .owl-nav [class*=owl-]:hover',
      // ' .content_left nav li a:hover , .content_left nav li a.active',
      '.add_btn, .noticlockbg , .bre_updbtn, .pro_editbtn, .addvistbnbtn , .export_btn:hover',
      '.deps_scroll .selected::after , .remove-hover-li .selected::after,.lds-ellipsis div , .shedule_btdcn , .export_file , .update_service_list_btn',
      '.cancel_update_btn:hover , .der_pgs.active',
      '.switch.checked , .payment-button',
      '.listtabfbc.active',
      `.owl-carousel .owl-nav button.owl-next:hover, .owl-carousel .owl-nav button.owl-prev:hover, .search_area .btn-primary, .cons_scroll li a:hover, .date_calender_tab.bookpg .item.active, .date_calender_tab .item:hover, .date_calender_tab.upcomingpg .item.active, .date_calender_tab.previouspg .item.active, .custom-control-input:checked ~ .custom-control-label::before, .btn_reportgt:hover, .btn_reportgt.active, .btn_reportgt.taghbc, .listtabfbc:hover, .bar_edifg:hover`, '.background-blue','.selected::after','.log-sec-btn','.log_secc_btn:before',
      '.change_btns , .log_secc_btn , .update_email_btn , .update_email_btn , .bre_updbtn'
    ];

    blueBackgroundSelector.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'background',
        color2
      );
    });

    /*Blue border color */
    const blueBorderColorSelector = [
      '.pro_pxd img , .side_adduserbar li.active span, .side_adduserbar li.correct span , .da_pgs:hover , .form-check-input:checked , .isCustomer',
      '.date_calender_tab.upcomingpg .item.active, .date_calender_tab.previouspg .item.active ',
      '.search_area .btn-primary',
      '.date_calender_tab .item:hover, .btn-primary  , .res_canc_btn , .der_pgs:hover , wrap_ares .active , .sec_pgw:hover',
      '.plus_editpanfgt, .tody_imgs, .upcom_imgtd,.scroll_conh .img_hgd,.customer .img_hgd  , .pro_pxd .customer-letter-image  , .bre_updbtncan',
      '.owl-carousel .owl-nav button.owl-next:hover, .owl-carousel .owl-nav button.owl-prev:hover, .search_area .btn-primary, .cons_scroll li a:hover, .date_calender_tab.bookpg .item.active, .date_calender_tab .item:hover, .date_calender_tab.upcomingpg .item.active, .date_calender_tab.previouspg .item.active, .custom-control-input:checked ~ .custom-control-label::before ,.page-item.active .page-link , .confirm-btn-container .btn-outline-secondary',
      // '.owl-carousel .owl-nav button.owl-next:hover, .owl-carousel .owl-nav button.owl-prev:hover, .search_area .btn-primary, .cons_scroll li a:hover, .date_calender_tab.bookpg .item.active, .date_calender_tab .item:hover, .date_calender_tab.upcomingpg .item.active, .date_calender_tab.previouspg .item.active, .custom-control-input:checked ~ .custom-control-label::before, .btn_reportgt:hover, .btn_reportgt.active, .btn_reportgt.taghbc',
      '.imbox_base , .customer-border , .prod_pxd .customerImage , .btn_reportgt:hover',
      '.main_custm_cover_htr .prof_barimgic  .profic_coverbcg , .basic-chip , .gateway-list ul li a.active ',
      //.profile_cust_mgsec .prof_barimgic .profic_coverbcg
      ' .customer .prof_barimgic .profic_coverbcg , .deps_scroll .selected , .appointments-detail-status , .der_pgs.active , .btn_reportgt.active , .btn_reportgt.taghbc , .btn_reportgt.taghbc , .viewhw_btngc ',
      // '.document-container .lds-ring div',
      `.owl-carousel .owl-nav button.owl-next:hover, .owl-carousel .owl-nav button.owl-prev:hover, .search_area .btn-primary, .cons_scroll li a:hover, .date_calender_tab.bookpg .item.active, .date_calender_tab .item:hover, .date_calender_tab.upcomingpg .item.active, .date_calender_tab.previouspg .item.active, .custom-control-input:checked ~ .custom-control-label::before, .btn_reportgt:hover, .btn_reportgt.active, .btn_reportgt.taghbc, .listtabfbc:hover, .bar_edifg:hover, .listtabfbc.active`,
      // '.listtabfbc.active',
    ];

    blueBorderColorSelector.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'border-color',
        color2
      );
    });

    /*Blue background color */
    //background-color: #6379FD
    const blueBackgroundColorSelector = [
      '.profile_csp.use_rabx:before,.page-item.active .page-link',
      '.profile_csp.wrapfsg:before',
      '.beau_box, .dash_headcn:after, .depar_numb li span , .wrap_ares .active',
      '.side_adduserbar li.active span ',
      '.side_adduserbar li.correct span,.btn-primary',
      '.da_pgs.active , .form-check-input:checked , .system-generated-container .active ',
      ' .background-blue , .dropdown-item:active , .theme-green .bs-datepicker-head , .theme-green .bs-datepicker-body table td span.selected ',
      ' .theme-green .bs-datepicker-body table td.selected span',
      ' .theme-green .bs-datepicker-body table td span[class*="select-"]::after',
      ' .theme-green .bs-datepicker-body table td[class*="select-"] span::after',
      ' .side_adduserbar li:before , .btn_reportgt:hover, .btn_reportgt.active , .btn_reportgt.taghbc , .viewhw_btngc , .ratin_items',
      '.browsectr .bg-success , .succes_cofbx h3::after , .price_boxs h2',
    ];
    blueBackgroundColorSelector.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'background-color',
        color2
      );
    });

    /*Blue border bottom color*/
    const blueBorderBottom = [
      '.personal_tab_pan_sec .nav-pills .nav-link:after, .card_bxr:after , .personal_tab_pan_sec .nav-tabs .nav-link:after',
    ];

    blueBorderBottom.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'border-bottom-color',
        color2
      );
    });

    /*Red background color*/
    const redBackgroundColorSelector = [
      '.depar_numb li.active span',
      '.depar_numb li:hover span,.modal-header,.btn-danger',
      '.manageuser_cardxpd .profile_manabx:before , .background-red',
      ' .tody_doctors .item:before , .price_boxs.curret-plan h2',
    ];
    redBackgroundColorSelector.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'background-color',
        color1
      );
    });

    /*Red back ground */
    const redBackgroundSelector = [
      '.import_file:hover , .export_btn,.bre_updbtncan , .confirm-btn-container .btn-outline-secondary:hover',
      '.addvistbnbtn:hover, .nxt_bt:hover, .res_canc_btn:hover, .bar_edifg',
      '.add_btn:hover, .noticlockbg:hover ,.bre_updbtn:hover, .pro_editbtn:hover, .addvistbnbtn:hover',
      '.shedule_btdcn.cancj, .import_file , .update_service_list_btn:hover , .cancel_update_btn , .payment-button:hover',
      // '.res_canc_btn:hover, .bre_updbtncan:hover, .btn_reportgt.taghbc:hover, .viewhw_btngc:hover, .bar_edifg'
    ];
    redBackgroundSelector.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'background',
        color1
      );
    });

    //border color: #FF5264
    const redBorderColorColorSelector = [
      ' .log_img ,.pro_pxd .consultant-border-color, .consultpro_pxd img, .scroll_consultgt .img_hgd , .profile_bs.consulpg .img_hgd,.isConsultant  , .profic_coverbcg , .btn-danger ',
      ' .depbdg_wrap .prof_barimgic .profic_coverbcg , .plus_editpanfgt, .tody_imgs, .upcom_imgtd, .quesel_pgtab .img_hgd, .red_circrack .profic_coverbcg , .cosult_boxbracwrap li.active .img_brackbrd',
      ' .consultant .prof_barimgic .profic_coverbcg , .queue-container .active , .cosult_boxbracwrap li:hover .img_brackbrd ',
      '.consultant-border-color,.bar_edifg',
      '.consultpro_pxd img',
      '.remove-hover-li .selected , .pro_pxd .consultant-letter-image',
      '.calendar-queue-list .da_pgs:hover',
      '.bar_edifg','.log_sec_box .form-control','.log_sec_box .form-control:focus','.primary-f-control'
    ];
    redBorderColorColorSelector.forEach((selector) => {
      this.changeStylesheetRule(
        adminThemeStyleSheet,
        selector,
        'border-color',
        color1
      );
    });
  }

  changeStylesheetRule(stylesheet, selector, property, value) {
    const s = this.document.styleSheets[4];

    selector = selector.toLowerCase();
    property = property.toLowerCase();
    value = value.toLowerCase();

    for (var i = 0; i < s.cssRules.length; i++) {
      var rule = s.cssRules[i];

      if (rule.selectorText === selector) {
        rule.style[property] = value;

        return;
      }
    }
    //stylesheet.insertRule(selector + " { " + property + ": " + value + "; }", 0);
    const index = stylesheet.insertRule(
      selector + ' { ' + property + ': ' + value + '; }',
      0
    );

    this.stylePreviousIndex.push(index);
  }
}
