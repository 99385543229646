<!-- <link rel="stylesheet" [href]="sanitizer.bypassSecurityTrustResourceUrl(styleUrl)"> -->
<!-- <link rel="stylesheet" [href]="sanitizer.bypassSecurityTrustResourceUrl(deviceUrl)"> -->
<!-- <link rel="stylesheet" [href]="sanitizer.bypassSecurityTrustResourceUrl('../assets/css/admin-style.css')">
<link rel="stylesheet" [href]="sanitizer.bypassSecurityTrustResourceUrl('../assets/css/admin-device.css')"> -->

<ng-container *ngIf="!showModuleLoader;else showLoader">
    <router-outlet></router-outlet>
</ng-container>
<ng-template #showLoader>
    <app-module-loader></app-module-loader>
</ng-template>

<app-splash-screen></app-splash-screen>