import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerAuthGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

}


@Injectable({
  providedIn: 'root',
})
/**
 * Authentication guard to check if business slugName is available in GeneralService
 */
export class CustomerBusinessNameAuthGuard implements CanActivate {
  constructor(private generalService: GeneralService, private router: Router) { }

  /**
   * @description — Interface that a class can implement to be a guard deciding if a route can be activated. If all guards return true, navigation will continue. If any guard returns false, navigation will be cancelled. If any guard returns a UrlTree, current navigation will be cancelled and a new navigation will be kicked off to the UrlTree returned from the guard.
   * 1. Return true if business name is available
   * 2. Navigate to business selection page if slugName is not available
   */
  canActivate() {

    // console.log("Sub domain",this.generalService.getSubDomain())
    // console.log("Slugname",localStorage.getItem('slugName'))
    // console.log("CId",localStorage.getItem('c_id'))
    
    !localStorage.getItem('c_id') && !this.generalService.slugName ? localStorage.removeItem('slugName') : '';
    if (this.generalService.getSubDomain() || (localStorage.getItem('c_id') && localStorage.getItem('slugName'))) {
      console.log("SUCCESS")
      return true                                     //1
    } else {
      this.router.navigateByUrl('/select-business');   //2
      console.log("FAILED")
      return false;
    }

  }
}
