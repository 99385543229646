import { Component, OnInit } from '@angular/core';
import { SplashScreenService } from '../services/splash-screen.service';
import Lottie, { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { colorify, flatten, getColors, replaceColor } from 'lottie-colorify';
@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.css']
})
export class SplashScreenComponent implements OnInit {

  options: AnimationOptions = {
    // path: 'https://assets6.lottiefiles.com/temporary_files/PH5YkW.json',
    path: 'assets/lottie-json/LoaderAnimationNew.json',
    loop: true,
    autoplay:true,
   
  };

  styles: Partial<CSSStyleDeclaration> = {
    // maxWidth: '1200px',
    // margin: '0 auto',
    

  };

  
  
  constructor(public ss: SplashScreenService) { }

  ngOnInit(): void {
  }

 
  animationCreated(animationItem: AnimationItem): void {
    console.log("ANIMATION ITEM",animationItem)
  }

}
