import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
	providedIn: 'root',
})
export class InternetConnectionTestService {
	public isConnected: boolean;

	constructor(private toastService: ToastrService) {}

	/**
	 * Purpose: Handle internet connection issue toasts when offline else error message is shown
	 * Modified By: Arjun Jayakumar
	 * Modified Date: 23-04-2024
	 */
	checkInternetConnection(err: any) {
		this.toastService.error(this.isConnected ? (err.message ? err.message : 'Something went wrong') : 'Please check your internet connection');
	}

	checkInternetConnectionOnNavigation() {
		const result = !this.isConnected;
		if (result) {
			this.toastService.error('Please check your internet connection');
		}
		return result;
	}
}
