import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../Services/menu.service';

@Component({
  selector: 'app-not-found-page404',
  templateUrl: './not-found-page404.component.html',
  styleUrls: ['./not-found-page404.component.css']
})
export class NotFoundPage404Component implements OnInit {

  constructor(
    public menuService: MenuService,
    public router: Router
  ) { }

  ngOnInit(): void {
    if (!this.menuService.invalidSlugName) {
      this.onNavigateToHome();
    }
  }

  /*Date:12/03/2021
  Task id:16506
  Created:Rahul Prasad
  Purpose:Function to navigate user from 404 page to selected route
  */
  onNavigateToHome() {
    const url = window.location.href;


    const isAdmin = url.search('/admin/');

    if (isAdmin > -1) {
      window.location.replace('/admin');
    } else {
      window.location.replace('/');
    }

    // window.location.replace('/admin');

  }

}
